<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="礼包名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入礼包名称"></a-input>
					</a-form-item>

					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
					</a-form-item>

					<a-form-item label="订单编号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单编号"></a-input>
					</a-form-item>

					<!--          <a-form-item label="订单流水号" name="outTradeNo" class="ui-form__item">-->
					<!--            <a-input v-model:value="formState.outTradeNo" placeholder="请输入订单流水号"></a-input>-->
					<!--          </a-form-item>-->

					<a-form-item label="支付方式" name="payMessageType" class="ui-form__item">
						<a-select v-model:value="formState.payMessageType" placeholder="请选择支付方式" allow-clear
							style="width: 195px;">
							<a-select-option :value="'wx_lite'">微信支付</a-select-option>
							<a-select-option :value="'alipay'">支付宝支付</a-select-option>
							<a-select-option :value="'card'">一卡通支付</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="支付时间" name="payTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.payTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<exportReport type="couponPacketOrderStrategy" :searchData="searchData"></exportReport>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 2500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'payTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.nickname || '-' }}</div>
								<div>用户手机号：{{ record.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'fees'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment * 0.0054).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'predict'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'payMessageType'">
							<div v-if="record.payMessageType === 'wx_lite' || record.payMessageType === 'zero'">微信支付</div>
							<div v-else-if="record.payMessageType === 'alipay'">支付宝支付</div>
							<div v-else-if="record.payMessageType === 'card'">一卡通支付</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'status'">
							
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" size="small" @click="onRefund(record)">退款</a-button>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="refundModalVibile" title="售后退款" width="750px" @cancel="onCancel" @ok="onRefuntSubmit">
			<a-spin :spinning="loading">
				<a-form ref="refundFormRef" :model="modelRef">
					<a-form-item label="订单号">
						{{ modelRef.payment }}
					</a-form-item>
					
					<a-form-item label="实付金额">
						{{ modelRef.payment }}
					</a-form-item>
					
					<a-form-item label="退款金额" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input-number :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		getCouponPacketOrderList
	} from "@/service/modules/report";
	import exportReport from '@/components/exportReport/exportReport.vue';
	export default {
		components: {
			exportReport
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				searchData: {},
				modelRef: {},
				formState: {},
				columns: [{
					title: '订单号',
					dataIndex: 'orderNo',
				}, {
					title: '交易时间',
					key: 'payTime',
				}, {
					title: '用户信息',
					key: 'userInfo'
				}, {
					title: '礼包名称',
					dataIndex: 'businessTitle',
				}, {
					title: '礼包价格（元）',
					dataIndex: 'totalFee',
				}, {
					title: '实付金额（元）',
					dataIndex: 'payment',
				}, {
					title: '微信手续费(0.54%)',
					key: 'fees'
				}, {
					title: '微信预计到账金额',
					key: 'predict'
				}, {
					title: '支付类型',
					key: 'payMessageType',
				}, {
					title: '订单状态', // todo
					key: 'status'
				}, {
					title: '退款状态', // todo
				}, {
					title: '失败原因', // todo
				}, {
					title: '操作', // todo
					key: 'action',
					fixed: 'right',
					align: 'center',
					width: 100
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				refundModalVibile: false,
				modelRef: {}
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				if (this.formState.payTime && this.formState.payTime.length === 2) {
					this.searchData.payStartTime = this.moment(this.formState.payTime[0].startOf('day')).unix();
					this.searchData.payEndTime = this.moment(this.formState.payTime[1].endOf('day')).unix();
				}
				this.loading = true;
				try {
					let ret = await getCouponPacketOrderList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onRefund(item) {
				this.modelRef = {
					id: item.id,
					orderNo: item.orderNo,
					payment: item.payment
				}
				this.refundModalVibile = true;
			},
			onCancel() {
				this.$refs.refundFormRef.resetFields();
				this.refundModalVibile = false;
			},
			onRefuntSubmit() {
				this.$refs.refundFormRef.validate().then(() => {
					this.$confirm({
						title: '提示',
						content: '确定执行退款吗？',
						onOk: async () => {
							// todo
						}
					})
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>